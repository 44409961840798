// Colors
$blue: #b950a1;
$dark-blue: #952b8f; //footer
$yellow: #eeb807;
$light-blue: #f3daec;
$dark-blue-one: #9b4496; //copyright
$dark-blue-two: #657e85;
$white: #fff;
$overlay-layer: rgba(185, 80, 160, .67);
$warning: rgba(175, 46, 46, 0.32);
$warningTxt: #af2e2e;
$success: rgba(46, 173, 84, 0.32);
$successTxt: #2eaf55;

// Fonts
$font: 'Cairo';
$tiny-size-reg: 100 14px #{$font};
$tiny-size-sbold: 200 14px #{$font};
$tiny-size-bold: 300 14px #{$font};
$tiny-size-black: 400 14px #{$font};

$small-size-reg: 100 16px #{$font};
$small-size-sbold: 200 16px #{$font};
$small-size-bold: 300 16px #{$font};
$small-size-black: 400 16px #{$font};

$med-size-reg: 100 20px #{$font};
$med-size-sbold: 200 20px #{$font};
$med-size-bold: 300 20px #{$font};
$med-size-black: 400 20px #{$font};

$large-size-reg: 100 25px #{$font};
$large-size-sbold: 200 25px #{$font};
$large-size-bold: 300 25px #{$font};
$large-size-black: 400 25px #{$font};

$half-big-size-reg: 100 30px #{$font};
$half-big-size-sbold: 200 30px #{$font};
$half-big-size-bold: 300 30px #{$font};
$half-big-size-black: 400 30px #{$font};

$big-size-reg: 100 35px #{$font};
$big-size-sbold: 200 35px #{$font};
$big-size-bold: 300 35px #{$font};
$big-size-black: 400 35px #{$font};