// Variables
@import "../../assets/css/vars";

.home-slider {
    .slide {
        width: 100%;
        height: calc(120vh - 207px);
        background-position: center;
        background-size: cover;

        .overlay {
          //  background-color: $overlay-layer;
            height: 100%;

            .container {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                h3 {
                    color: $white;
                    font: $big-size-sbold;
                }
            }
        }
    }

    .carousel {
        .slider-wrapper {
            direction: ltr;
        }

        .control-arrow {
            font-size: 30px;

            &:hover {
                background-color: unset;
            }

            &::before {
                padding: 0;
                margin: 0;
                border: 0;
                color: $white;
                font-family: 'Font Awesome 5 Free';
            }

            &.control-prev {
                left: 40px;

                &::before {
                    content: "\f359";
                }
            }

            &.control-next {
                right: 40px;

                &::before {
                    content: "\f35a";
                }
            }
        }
    }

    @media (max-width: 768px) {
        .slide {
            height: calc(100vh - 186px);

            h3 {
                font: $med-size-sbold !important;
                padding: 0 50px;
            }
        }

        .carousel {
            .control-arrow {
                &.control-prev {
                    left: 20px;
                }

                &.control-next {
                    right: 20px;
                }
            }
        }
    }
}

.user-buttons {
    text-align: center;

    .u-btn {
        padding: 30px 0;
        color: $white;
        font: $large-size-bold;
        display: block;

        &.sign-in {
            background-color: $dark-blue
        }

        &.sign-up {
            background-color: $blue;
        }

        i {
            margin-left: 22px;
            transition: all 0.2s;
        }

        &:hover {
            i {
                transform: scale(1.5);
            }
        }
    }

    @media (max-width: 768px) {
        .u-btn {
            font: $small-size-bold;

            i {
                margin-left: 4px;
            }
        }
    }
}

.about-teacher {
    text-align: right;

    .teacher-bio {
        p {
            color: $dark-blue;
            font: $med-size-reg;
            line-height: 37px;
            white-space: pre-line;
        }

        img {
            margin-top: -20px;
            width: 100%
        }
        span{
            font-size: 22px;
            
        }
    }

    @media (max-width: 768px) {
        .teacher-bio {
            img {
                margin-top: 20px;
            }
        }
    }
}

.free-videos {
    background-color: $light-blue;
    display: flow-root;

    .video {
        overflow: hidden;
        border-radius: 8px;

        .thumb {
            height: 228px;
            position: relative;
            width: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }

            .hover-layer {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: $overlay-layer;
                display: flex;
                align-items: center;
                justify-content: center;
                left: 0;
                top: -228px;
                opacity: 0;
                transition: all 0.2s;

                i {
                    color: $white;
                    font-size: 45px;
                }
            }
        }

        .video-title {
            padding: 20px 20px;
            background-color: $white;
            text-align: center;
            min-height: 114px;
            display: flex;
            align-items: center;
            justify-content: center;

            h4 {
                color: $dark-blue;
                font: $med-size-bold;
                margin-bottom: 0;
            }
        }

        &:hover {
            .thumb {
                .hover-layer {
                    top: 0;
                    opacity: 1;
                }
            }
        }
    }

    .all-videos {
        margin: 50px auto;
        display: table;
        padding: 10px 35px;
        border-radius: 50px;
        font: $small-size-bold;
        background-color: $blue;
        color: $white;
        transition: all 0.2s;

        &:hover {
            background-color: $dark-blue;
        }
    }

    @media (max-width: 768px) {
        .video {
            margin-bottom: 30px;
        }

        .all-videos {
            margin-top: 20px;
        }
    }
}